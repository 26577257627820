import { useState, useEffect } from "react";

// CSS
import style from "../assets/css/pages/maintenance.module.css";

// IMG
import gabiLogo from "../assets/img/gabidigital-logo.svg";
import gabiFotoPerfil from "../assets/img/pages/foto-perfil-gabi.png";

// ICONS
import behance from "../assets/img/icons/behance.svg";
import linkedin from "../assets/img/icons/linkedin.svg";
import github from "../assets/img/icons/github.svg";
import maintenance from "../assets/img/icons/maintenance.svg";

// DETAILS
import triangles from "../assets/img/details/triangle-pattern.svg";

const Maintenance = () => {
  const [display, setDisplay] = useState("");
  const [opacity, setOpacity] = useState("1");

  const handleLoad = () => {
    setOpacity("0");
    setTimeout(() => {
      setDisplay("none");
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      <div id={style.loader} style={{ display: display, opacity: opacity }}>
        Aguarde...
      </div>
      <main className="flex-row">
        <section id={style.info} className="scroll">
          <header className="flex-column">
            <div>
              <div className="flex-row">
                <div className={style["info-personal"]}>
                  <img
                    src={gabiLogo}
                    alt="Logo Gabriela Gonçalves."
                    className={style["brand-logo"]}
                  />
                  <h1 id={style.headerH1} className="text-subtitle-small">
                    Dev Front-End | Designer UI/UX
                  </h1>
                  <span className="grey-a010-line"></span>
                </div>
                <img
                  src={gabiFotoPerfil}
                  alt="Foto de Perfil com o rosto de Gabriela Gonçalves."
                  className={style["profile-photo"]}
                />
              </div>
              <div className={style["maintenance-warning"]}>
                <img
                  src={maintenance}
                  alt="Ícone de engrenagens."
                  className={style["maintenance-icon"]}
                />
                <p className="text-body">
                  Este site está em manutenção. Se quiser conhecer um pouco mais
                  sobre o meu trabalho, acesse os links abaixo:
                </p>
              </div>
            </div>
            <div className={style["social-media"]}>
              <a
                href="https://www.behance.net/gabrielalg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={behance}
                  alt="Logo Gabriela Gonçalves."
                  className={style["social-media__item"]}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/gabrielalg/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedin}
                  alt="Logo Gabriela Gonçalves."
                  className={style["social-media__item"]}
                />
              </a>
              <a
                href="https://github.com/gabrielalg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={github}
                  alt="Logo Gabriela Gonçalves."
                  className={style["social-media__item"]}
                />
              </a>
              <a
                href="mailto:hi.gabidigital@gmail.com"
                className={style["social-media__email"]}
              >
                <span>hi.gabidigital@gmail.com</span>
              </a>
            </div>
          </header>
        </section>
        <section id={style.about} className="scroll">
          <div className={style.card}>
            <h2>Olá! 👋</h2>
            <p className="text-body">
              Meu nome é Gabriela e minha trajetória profissional começa lá em
              2017. Naquele ano eu ainda fazia faculdade de Comunicação Social
              (Midialogia) e trabalhava em uma produtora de vídeos como
              Assistente de Produção.
            </p>
            <p className="text-body">
              No final de 2018 eu já estava formada e não queria mais continuar
              trabalhando na área audiovisual. Pensei: o que eu sei e gosto de
              fazer e que poderia colocar como um objetivo daqui pra frente?
              Sites!
            </p>
            <p className="text-body">
              E foi assim que no início de 2019 eu saí da produtora em que
              estava e me aventurei no mundo do Design e da Programação.
              Recentemente concluí uma pós-graduação em Desenvolvimento
              Front-end e, no momento, estou trabalhando como Desenvolvedora
              React na BEES.
            </p>
            <p className="text-body">
              Quer conversar sobre tecnologia ou design? Me chama que eu vou
              amar! ❤️ Pra mim código e design tem que andar assim, ó,
              grudadinhos 🤞.
            </p>
            <p className="text-body">
              📌 Algumas habilidades: React (JS), Javascript, Typescript, HTML,
              CSS, Jest, Enzyme, Git, GitHub, Prototipação (Adobe XD e Figma),
              Wireframes, Fluxos de Usuários.
            </p>
          </div>
          <footer>
            <p id={style.copyright}>
              <small>&copy; Gabriela Gonçalves 2021</small>
            </p>
          </footer>
        </section>
        <div className={style.overlay}>
          {/* <span className={style.dot}></span>
          <span className={style['dot-outline']}></span> */}
          <img
            src={triangles}
            alt="Textura de triângulos."
            className={style["triangle"]}
          />
        </div>
      </main>
    </>
  );
};

export default Maintenance;
