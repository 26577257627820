import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// ---- COMPONENTES 
import ScrollToTop from './components/ScrollToTop.js';

// ---- PÁGINAS
import Maintenance from './pages/maintenance.js';
import Assinatura from './pages/assinatura.js';

// ---- CSS
import './assets/css/main.css';

ReactDOM.render(
    <React.StrictMode>
      <Router>

        <ScrollToTop />

          <Switch>
            <Route exact path="/">
              <Maintenance />
            </Route>
            <Route exact path="/assinatura">
              <Assinatura />
            </Route>
            {/* <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/site-jean-goes">
              <h1>Site Jean Goes</h1>
            </Route>
            <Route exact path="/site-cbe-7">
              <h1>Site CBE 7</h1>
            </Route>
            <Route exact path="/site-icleanie">
              <h1>Site iCleanie</h1>
            </Route> */}
          </Switch>

      </Router>
    </React.StrictMode>,
  document.getElementById('root')
);
