import { useEffect } from 'react';

const Assinatura = () => {

useEffect(() => {
  document.title = "Assinatura | Gabi Digital";  
});

  return <>
    <table style={{margin: "0"}} width="320" height="100" cellspacing="0" cellpadding="0">
      <tr style={{marginLeft: "20px"}}>
        <td rowspan="3" style={{width:"100px", paddingRight: "10px"}}>
        <img src={process.env.PUBLIC_URL + "/assinatura/fav-email.png"} alt="Ícone Gabi Digital" style={{width:"100px", height:"100px"}} />
        </td>
        <td>
        <h2 style={{
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          color: "#232426",
          fontWeight: "initial",
          fontSize: "20px",
          margin: "0",
          paddingTop: "5px",
          display: "inherit",
          verticalAlign: "middle"
        }}	
        ><strong style={{fontWeight: "bold", display:"inline"}}>Gabriela</strong> Gonçalves</h2>
        </td>
      </tr>
      <tr>
        <td style={{paddingTop: "5px"}}>
          <img src={process.env.PUBLIC_URL + "/assinatura/phone-icon.png"} alt="Ícone telefone."
          style={{ width: "18px",
                  height: "18px",
                  verticalAlign: "middle",
                  paddingBottom: "1px",
                  marginRight: "5px",
                  display:"inline-block"}} />
          <p style={{
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
            color: "#232426",
            fontSize: "14px",
            display: "inline"
          }}> 
            (19) 999454182 
          </p>
        </td>
      </tr>
      <tr>
        <td style={{paddingBottom: "10px"}}> 
        <img src={process.env.PUBLIC_URL + "/assinatura/case-icon.png"} alt="Ícone porfólio." 
        style={{ width: "18px",
                height: "18px",
                verticalAlign: "middle",
                marginBottom: "2px",
                marginRight: "5px",
                display:"inline-block" }}/>
        <a href="https://www.gabidigital.work/" target="_blank" rel="noreferrer" 
        style={{
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          color: "#232426",
          textDecoration: "none",
          fontSize: "13px",
          display: "inline"
        }}> 
          www.gabidigital.work </a>
        </td>
      </tr>
    </table>

  </>
};

export default Assinatura;